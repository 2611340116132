import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { usePlausible } from 'next-plausible';

import Layout from '@/components/layout';

export default function Custom404() {
    const router = useRouter();
    const plausible = usePlausible();

    useEffect(() => {
        plausible('404', { path: router.asPath });
    }, [router]);

    return (
        <Layout title="404">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-xl-4 my-6">
                        <div className="text-center">
                            {/* <!-- Preheading --> */}
                            <h6 className="text-uppercase text-muted mb-4">
                                Fehler 404
                            </h6>

                            {/* <!-- Heading --> */}
                            <h1 className="display-4 mb-3">
                                Die Seite konnte nicht gefunden werden 😭
                            </h1>

                            {/* <!-- Subheading --> */}
                            <p className="text-muted mb-4">
                                Vielleicht suchen Sie ja keine Webseite, sondern Manuel Neuer?
                            </p>

                            {/* <!-- Button --> */}
                            <a href="#" onClick={() => router.back()} className="btn btn-lg btn-primary">
                                Zurück
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...await serverSideTranslations(locale, ['common', 'menu', 'error'])
        }
    }
}
